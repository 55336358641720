/**
 * A class of keywords defined to take advantage of the browser's localStorage feature to remember the user's preferences.
 * In this way, features such as language will be remembered the next time you log in.
 */
export enum Storage {
  /**
   * The key holding the user's preferred language.
   * For example: `en` or `tr`
   */
  PREFERRED_LANG = "preferred-lang",

  /**
   * The key that holds the user's most recent project sorting preference.
   * For example: `size`
   */
  SORT_BY = "sort-by",

  /**
   * The key that holds the user's last selected project ID.
   * For example: `4`
   */
  SELECTED_PROJECT = "selected-project",
}

/**
 * Defined and theme compatible graphic colors.
 */
export const CHART_COLORS: string[] = [
  "rgb(80, 134, 216, 0.6)",
  "rgb(80, 216, 183, 0.6)",
  "rgb(232, 97, 97, 0.6)",
  "rgb(216, 161, 80, 0.6)",
  "rgb(123, 80, 216, 0.6)",
  "rgb(216, 80, 161, 0.6)",
  "rgb(140, 197, 69, 0.6)",
  "rgb(186, 188, 184, 0.6)",
];
