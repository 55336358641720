import { Endpoint, useApi } from "Context/ApiContextProvider";
import { useEffect, useMemo, useState } from "react";
import { ICreditState } from "./Navbar.types";
import { IErrorResponse, IFile } from "interfaces";
import { MessageType, useMessages } from "Context/MessagesContextProvider";
import { useAuthContext } from "Context/AuthContextProvider";
import { useTranslation } from "react-i18next";

/**
 * A custom hook for fetching user's credit.
 */
export function useCredit() {
  // Destruct `performApiRequest` function to fetch credit.
  const { performApiRequest } = useApi();

  // Stores credit and is fetching with state.
  // Credit is 0 and fetching credit is true as default.
  const [state, setState] = useState<ICreditState>({
    credit: 0,
    fetchingCredit: true,
  });

  /**
   * It receives the user's credit information asynchronously from the server.
   */
  async function fetchCredit() {
    await performApiRequest({ endpoint: Endpoint.CREDIT, method: "GET" })
      .then(async (response) => {
        if (response.ok) {
          const data: string = await response.text();
          setState((prevState) => ({
            ...prevState,
            credit: Number(data),
          }));
        }
      })
      .catch((error) => console.error(error))
      .finally(() =>
        setState((prevState) => ({ ...prevState, fetchingCredit: false }))
      );
  }

  // Return states and function to external use.
  return { ...state, fetchCredit };
}

/**
 * A custom hook for fetching unused files.
 *
 * @returns An object that holds unused files state and functions.
 */
export function useFetchUnusedFiles() {
  const { t } = useTranslation();

  // Destruct `isAuthenticated` state from auth context hook for cheching is user authenticated or not.
  const { isAuthenticated } = useAuthContext();

  // Destruct `performApiRequest` funciton from custom hook for fetching unused files.
  const { performApiRequest } = useApi();

  // Destruct `addMessage` function from custom hook for adding messages whenever gets error from response.
  const { addMessage } = useMessages();

  // Define state for unused files with `IFile` interface as array.
  // State starts as `null` by default.
  const [unusedFiles, setUnusedFiles] = useState<IFile[] | null>(null);

  /**
   * An asynchronous function that fetchs unused files and updates the state.
   */
  async function fetchUnusedFiles() {
    // Stop operations if the user is not logged in.
    if (!isAuthenticated) {
      return;
    }

    await performApiRequest({
      endpoint: Endpoint.FILES_TOBEDELETED,
      method: "GET",
    })
      .then(async (response) => {
        if (response.ok) {
          const data: IFile[] = await response.json();
          setUnusedFiles(data);
        } else {
          const errorResponse: IErrorResponse = await response.json();
          addMessage(
            errorResponse.message,
            MessageType.ERROR,
            errorResponse.fieldErrors
          );
        }
      })
      .catch((error) => console.error(error));
  }

  async function deleteUnusedFile(unusedFile: IFile) {
    await performApiRequest({
      endpoint: Endpoint.FILES,
      method: "DELETE",
      dynamicData: unusedFile.id,
    })
      .then(async (response) => {
        if (response.ok) {
          addMessage(t("file-deleted-successfuly"), MessageType.SUCCESS);
          setUnusedFiles((prevUnusedFiles) => {
            if (prevUnusedFiles) {
              const updatedUnusedFiles: IFile[] = prevUnusedFiles.filter(
                (file) => file.id !== unusedFile.id
              );

              return updatedUnusedFiles;
            }

            return null;
          });
        } else {
          const errorResponse: IErrorResponse = await response.json();
          addMessage(
            errorResponse.message,
            MessageType.ERROR,
            errorResponse.fieldErrors
          );
        }
      })
      .catch(() =>
        addMessage(t("an-unknown-error-has-occurred"), MessageType.ERROR)
      );
  }

  // Perform effect whenever the hook component mounts.
  useEffect(() => {
    // Call asynchronous function to fetch unused files.
    fetchUnusedFiles();

    // eslint-disable-next-line
  }, []);

  // Return states for external use.
  return { unusedFiles, deleteUnusedFile };
}
