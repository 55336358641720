import { createContext, useContext, useEffect, useState } from "react";

/**
 * Defines theme keywords.
 */
type Theme = "light" | "dark";

/**
 * The interface that determines the states that the theme context will have.
 */
interface ThemeContextType {
  /**
   * Parameter specifying the theme color.
   */
  theme: Theme;
}

/**
 * Interface for theme context provider.
 */
interface ThemeProviderProps {
  children: React.ReactNode;
}

/**
 * Context created for the theme with the `createContext` function from React.
 */
const ThemeContext = createContext<ThemeContextType | null>(null);

/**
 * Custom hook to use the theme context provider.
 * If it cannot use the hook it throws an error.
 *
 * @returns Theme context
 */
export function useTheme() {
  const themeContext = useContext(ThemeContext);

  if (!themeContext) {
    throw new Error("useTheme must be used within a ThemeProvider");
  }

  return themeContext;
}

/**
 * Simply the hook that holds the theme and listens for when the browser (system) theme changes and updates the state.
 *
 * @returns
 */
export function ThemeProvider({ children }: ThemeProviderProps) {
  // Holds theme in state with `Theme` type.
  // Get your browser (system) theme and check for dark theme.
  // If so, set the `theme` state to `dark`. If not, set to `light`
  const [theme, setTheme] = useState<Theme>(
    window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light"
  );

  // Perform effect when hook component is mounts.
  useEffect(function () {
    // Add a listener to window for listen to changing browser theme.
    // And update `theme` state with new theme.
    window
      .matchMedia("(prefers-color-scheme: dark)")
      .addEventListener("change", (event) => {
        const newColorScheme = event.matches ? "dark" : "light";
        setTheme(newColorScheme);
      });
  }, []);

  /**
   * Defines theme context provider's values.
   */
  const contextValues: ThemeContextType = {
    theme,
  };

  // Provide theme context to children components.
  return (
    <ThemeContext.Provider value={contextValues}>
      {children}
    </ThemeContext.Provider>
  );
}
