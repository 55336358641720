import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { HTMLProps } from "react";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";

import classNames from "classnames";

interface DropdownListItemProps extends HTMLProps<HTMLDivElement> {
  /**
   * Optional dropdown list item icon
   */
  icon?: IconProp;

  /**
   * Optional boolean parameter to set the selected sign
   */
  selected?: boolean;

  /**
   * Determines whether the DropdownListItem has an indicator.
   */
  hasInditacor?: boolean | null;
}

/**
 * Re-usable dropdown list item component. Only render it a DropdownList compoennt.
 * 
 * @returns DropdownListItem component
 */
function DropdownListItem({
  children,
  className,
  icon,
  selected,
  hasInditacor,
  ...rest
}: DropdownListItemProps) {
  return (
    <div
      className={classNames(
        "dropdown-list-item",
        {
          "with-icon": icon,
          selected: selected,
        },
        className
      )}
      tabIndex={0}
      {...rest}
    >
      {hasInditacor && <div className="dropdown-indicator"></div>}
      {icon && <FontAwesomeIcon icon={icon} />}
      {children}
      {selected && <FontAwesomeIcon icon={solid("check")} />}
    </div>
  );
}

export default DropdownListItem;
