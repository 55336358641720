import { IUserSettings, TLanguages } from "interfaces";
import { createContext, useContext, useEffect, useState } from "react";
import { useAuthContext } from "./AuthContextProvider";
import { Endpoint, useApi } from "./ApiContextProvider";
import {
  getPreferredLangCode,
  detectBrowserLangCode,
  changeLanguageTo,
  getCurrentLangCode,
} from "i18n/config";

interface UserSettingsContextType {
  firstName: string | null;
  lastName: string | null;
  fullName: string | null;
  locale: TLanguages;
  fetchUserSettings: () => Promise<void>;
  changeUserLocale: () => Promise<void>;
  changingUserLocale: boolean;
}

interface UserSettingsProviderProps {
  children: JSX.Element;
}

const UserSettingsContext = createContext<UserSettingsContextType | null>(null);

export const useUserSettings = () => {
  const userSettingsContext = useContext(UserSettingsContext);

  if (!userSettingsContext) {
    throw new Error(
      "useUserSettings must be used within a UserSettingsProvider"
    );
  }

  return userSettingsContext;
};

export const UserSettingsProvider = ({
  children,
}: UserSettingsProviderProps) => {
  const { isAuthenticated } = useAuthContext();
  const { performApiRequest } = useApi();

  const [userSettings, setUserSettings] = useState<IUserSettings | null>(null);
  const [changingUserLocale, setChangingUserLocale] = useState<boolean>(false);

  /**
   * Fetch user settings whenever user is authenticated and updates state.
   */
  async function fetchUserSettings() {
    // Return the function is user is not authenticated.
    if (!isAuthenticated) {
      return;
    }

    setChangingUserLocale(true);

    await performApiRequest({
      endpoint: Endpoint.USERS_SETTINGS,
      method: "GET",
    })
      .then(async (response) => {
        if (response.ok) {
          const data: IUserSettings = await response.json();
          setUserSettings(data);
        }
      })
      .finally(() => setChangingUserLocale(false));
  }

  /**
   * Changes the user's locale and updates settings accordingly.
   * If the user is authenticated, sends a request to the server to update the locale.
   */
  async function changeUserLocale() {
    // Get the user's preferred language code from settings, browser, or use a default.
    let langCode =
      userSettings?.locale || getPreferredLangCode() || detectBrowserLangCode();

    // Determine the new language code based on the current language code.
    const newLangCode = langCode === "tr" ? "en" : "tr";

    changeLanguageTo(newLangCode);

    // Update user settings with the new language code.
    setUserSettings({
      ...(userSettings || {
        firstName: "",
        lastName: "",
      }),
      locale: newLangCode,
    });

    // Update the document language for better accessibility.
    document.documentElement.lang = newLangCode;

    // Send a request to the server to update the user's locale if authenticated.
    if (isAuthenticated) {
      await performApiRequest({
        endpoint: Endpoint.USERS_LOCALE,
        method: "POST",
      });
    }
  }

  // Perform effect whenever `userSettings` state changed.
  useEffect(() => {
    // Get the lang code from user settings context state or local storage or browser.
    let langCode: string = userSettings?.locale || getCurrentLangCode();

    // Update the document language for better accessibility.
    document.documentElement.lang = langCode;
  }, [userSettings]);

  /**
   * Defines `UserSettingsProvider` hook props for external use.
   */
  const contextValues: UserSettingsContextType = {
    firstName: userSettings?.firstName || null,
    lastName: userSettings?.lastName || null,
    fullName: userSettings?.lastName
      ? `${userSettings.firstName} ${userSettings.lastName}`
      : userSettings?.firstName || null,
    locale: userSettings?.locale || "en",
    fetchUserSettings: fetchUserSettings,
    changeUserLocale: changeUserLocale,
    changingUserLocale: changingUserLocale,
  };

  // Provide the states and function to children components.
  return (
    <UserSettingsContext.Provider value={contextValues}>
      {children}
    </UserSettingsContext.Provider>
  );
};
