import { IErrorResponse, IProject, ISelectedProjectDetails } from "interfaces";
import {
  Dispatch,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { useAuthContext } from "./AuthContextProvider";
import { Endpoint, useApi } from "./ApiContextProvider";
import { SortBy } from "Pages/Projects/Components/Sidebar/Sidebar.types";
import { Storage } from "Utils/Constants";
import { MessageType, useMessages } from "./MessagesContextProvider";
import { useTranslation } from "react-i18next";

interface ProjectsContextType {
  projects: IProject[] | null;
  setProjects: Dispatch<SetStateAction<IProject[] | null>>;
  selectedProjectId: number | null;
  setSelectedProjectId: Dispatch<SetStateAction<number | null>>;
  selectedProject: ISelectedProjectDetails | null;
  setSelectedProject: Dispatch<SetStateAction<ISelectedProjectDetails | null>>;
  fetchingProjects: boolean;
  fetchProjects: () => Promise<void>;
  changeProjectName: (id: number, newName: string) => Promise<void>;
  deleteProjectForever: (project: IProject | null) => Promise<void>;
  sortProjects: (sortBy: SortBy) => void;
  fetchSelectedProjectDetails: () => Promise<void>;
}

interface ProjectsProviderProps {
  children: React.ReactNode;
}

const ProjectsContext = createContext<ProjectsContextType | null>(null);

export const useProjects = () => {
  const projectsContext = useContext(ProjectsContext);

  if (!projectsContext) {
    throw new Error("useProjects must be used within a ProjectsProvider");
  }

  return projectsContext;
};

export const ProjectsProvider = ({ children }: ProjectsProviderProps) => {
  const { isAuthenticated } = useAuthContext();
  const { performApiRequest } = useApi();
  const { addMessage } = useMessages();
  const { t } = useTranslation();

  const [projects, setProjects] = useState<IProject[] | null>(null);
  const [fetchingProject, setFetchingProjects] = useState<boolean>(true);
  const [selectedProjectId, setSelectedProjectId] = useState<number | null>(
    null
  );
  const [selectedProject, setSelectedProject] =
    useState<ISelectedProjectDetails | null>(null);

  async function fetchProjects() {
    if (isAuthenticated)
      await performApiRequest({
        endpoint: Endpoint.PROJECTS,
        method: "GET",
      })
        .then(async (response) => {
          if (response.ok) {
            const data: IProject[] = await response.json();

            const sortBy = localStorage.getItem(
              Storage.SORT_BY
            ) as SortBy | null;
            const selectedProjectIdFromLocal = localStorage.getItem(
              Storage.SELECTED_PROJECT
            );

            setProjects(sortBy ? sortAndReturnProjects(data, sortBy) : data);

            if (selectedProjectIdFromLocal) {
              const selected: IProject | undefined = data.find(
                (project) => project.id === Number(selectedProjectIdFromLocal)
              );

              if (selected) {
                setSelectedProjectId(selected.id);

                localStorage.setItem(
                  Storage.SELECTED_PROJECT,
                  selected.id.toString()
                );
              } else {
                localStorage.removeItem(Storage.SELECTED_PROJECT);
              }
            }

            return response;
          }
        })
        .finally(() => setFetchingProjects(false));
  }

  async function fetchSelectedProjectDetails() {
    if (!selectedProjectId) {
      return;
    }

    await performApiRequest({
      endpoint: Endpoint.PROJECTS,
      method: "GET",
      dynamicData: selectedProjectId.toString(),
    }).then(async (response) => {
      if (response.ok) {
        const data: ISelectedProjectDetails = await response.json();
        setSelectedProject(data);
      } else {
        const errorResponse: IErrorResponse = await response.json();
        addMessage(
          errorResponse.message,
          MessageType.ERROR,
          errorResponse.fieldErrors
        );
      }
    });
  }

  const changeProjectName = async (id: number, newName: string) => {
    if (isAuthenticated && projects) {
      const project = projects.find((project) => project.id === id);

      if (project && project.name !== newName) {
        await performApiRequest({
          endpoint: Endpoint.PROJECTS,
          method: "PATCH",
          body: { id: id, name: newName },
        }).then(async (response) => {
          if (response.ok) {
            const updatedProject: IProject = await response.json();
            const updatedProjects = projects.map((project) =>
              project.id === updatedProject.id
                ? { ...project, name: updatedProject.name }
                : project
            );

            setSelectedProject((prevSelectedProject) => {
              if (prevSelectedProject) {
                return {
                  ...prevSelectedProject,
                  name: updatedProject.name,
                };
              }

              return null;
            });
            setProjects(updatedProjects);
            addMessage(t("project-successfully-renamed"), MessageType.SUCCESS);
          } else {
            const errorResponse: IErrorResponse = await response.json();
            addMessage(
              errorResponse.message,
              MessageType.ERROR,
              errorResponse.fieldErrors
            );
          }
        });
      }
    }
  };

  const deleteProjectForever = async (project: IProject | null) => {
    if (project && projects && isAuthenticated) {
      await performApiRequest({
        endpoint: Endpoint.PROJECTS,
        method: "DELETE",
        dynamicData: project.id,
      }).then(async (response) => {
        if (response.ok) {
          setSelectedProjectId(null);
          localStorage.removeItem(Storage.SELECTED_PROJECT);
          setProjects(projects.filter((p) => p.id !== project.id));
          addMessage(t("project-successfully-deleted"), MessageType.SUCCESS);
        } else {
          const errorResponse: IErrorResponse = await response.json();
          addMessage(
            errorResponse.message,
            MessageType.ERROR,
            errorResponse.fieldErrors
          );
        }
      });
    }
  };

  /**
   * Sorts projects by sortBy parameter then returns it and stores sortBy in local storage.
   * @param sortBy Sort option
   */
  const sortProjects = (sortBy: SortBy = "id") => {
    if (projects === null) {
      return;
    }

    const sortedProjects = sortAndReturnProjects(projects, sortBy);

    // Save sortBy info in local storage.
    localStorage.setItem(Storage.SORT_BY, sortBy);

    if (sortedProjects) {
      // Finally set sorted projects.
      setProjects(sortedProjects);
    }
  };

  const sortAndReturnProjects = (
    projects: IProject[],
    sortBy: SortBy
  ): IProject[] | null => {
    if (projects === null) {
      return null;
    }

    // Define project array to store sorted projects.
    let sortedProjects: IProject[];

    // Sort projects by soryBy parameter.
    // Then assign to sortedProjects variable.
    switch (sortBy) {
      case "name":
        sortedProjects = [...projects].sort((a, b) =>
          a.name.localeCompare(b.name)
        );
        break;

      case "size":
        sortedProjects = [...projects].sort(
          (a, b) => Number(b.totalDatasetCount) - Number(a.totalDatasetCount)
        );
        break;

      case "created":
        sortedProjects = [...projects].sort(
          (a, b) => Number(a.createDate) - Number(b.createDate)
        );
        break;

      case "updated":
        sortedProjects = [...projects].sort(
          (a, b) => Number(a.updateDate) - Number(b.updateDate)
        );
        break;

      case "id":
      default:
        sortedProjects = [...projects].sort(
          (a, b) => Number(a.id) - Number(b.id)
        );
        break;
    }

    return sortedProjects;
  };

  useEffect(() => {
    if (selectedProjectId) {
      localStorage.setItem(
        Storage.SELECTED_PROJECT,
        selectedProjectId.toString()
      );
      fetchSelectedProjectDetails();
    } else {
      setSelectedProject(null);
    }

    // eslint-disable-next-line
  }, [selectedProjectId]);

  useEffect(() => {
    if (selectedProjectId) {
      fetchSelectedProjectDetails();
    }

    // eslint-disable-next-line
  }, []);

  const contextValues: ProjectsContextType = {
    projects: projects,
    setProjects: setProjects,
    selectedProjectId: selectedProjectId,
    setSelectedProjectId: setSelectedProjectId,
    selectedProject: selectedProject,
    setSelectedProject: setSelectedProject,
    fetchingProjects: fetchingProject,
    fetchProjects: fetchProjects,
    changeProjectName: changeProjectName,
    deleteProjectForever: deleteProjectForever,
    sortProjects: sortProjects,
    fetchSelectedProjectDetails: fetchSelectedProjectDetails,
  };

  return (
    <ProjectsContext.Provider value={contextValues}>
      {children}
    </ProjectsContext.Provider>
  );
};
