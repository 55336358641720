import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import { Suspense, lazy, useEffect, useState } from "react";
import { useAuthContext } from "./Context/AuthContextProvider";
import { useTranslation } from "react-i18next";
import { useUserSettings } from "Context/UserSettingsProvider";
import { usePlatform } from "Context/PlatformContextProvider";

import Footer from "./Components/Layout/Footer";
import Header from "Components/Layout/Header";
import MessagesContainer from "Components/Layout/MessagesContainer";
import LoadingScreen from "Components/Common/LoadingScreen";
import OfflinePage from "Pages/OfflinePage";

const Learn = lazy(() => import("./Pages/Learn"));
const LearnForLanding = lazy(() => import("./Pages/LearnForLanding"));
const ContactUs = lazy(() => import("./Pages/ContactUs"));
const Projects = lazy(() => import("./Pages/Projects"));
const CreateTask = lazy(() => import("./Pages/CreateTask"));
const UploadFile = lazy(() => import("./Pages/UploadFile"));
const Error404 = lazy(() => import("./Pages/Error404"));
const TaskDetails = lazy(() => import("./Pages/TaskDetails/TaskDetails"));
const FilePage = lazy(() => import("./Pages/FilePage"));
const Search = lazy(() => import("./Pages/Search"));
const Profile = lazy(() => import("./Pages/Profile"));
const Credits = lazy(() => import("./Pages/Credits"));
const InformPayment = lazy(() => import("./Pages/InformPayment"));
const CreditManagement = lazy(() => import("./Pages/CreditManagement"));
const Landing = lazy(() => import("./Pages/Landing"));
const UnusedFilesPage = lazy(() => import("./Pages/UnusedFilesPage"));
const DeleteAccountPage = lazy(() => import("./Pages/DeleteAccountPage"));
const TermsOfUsePage = lazy(() => import("./Pages/TermsOfUsePage"));
const PrivacyPage = lazy(() => import("./Pages/PrivacyPage"));
const FileUpdateColumnsPage = lazy(
  () => import("./Pages/FileUpdateColumnsPage")
);
const DocsPage = lazy(() => import("./Pages/DocsPage"));

/**
 * Scrolls to top on every page changed.
 */
function ScrollToTop() {
  // Use location hook for getting pathname.
  const { pathname } = useLocation();

  // Whenever the pathname changes, scroll to the top
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  // It's a component and returns nothing.
  return null;
}

/**
 * A redirect function to Keycloak login page when user not auth.
 */
function LoginRedirector() {
  // Destructing `login` function to redirect to Keycloak login page.
  const { login } = useAuthContext();

  // Perform effect when component is mounts.
  useEffect(() => {
    // Redirect Keycloak login page.
    login();

    // eslint-disable-next-line
  }, []);

  // It's a component and returns nothing.
  return null;
}

/**
 * React project main entry functional component.
 */
function App() {
  const { t } = useTranslation();
  const { fetchUserSettings } = useUserSettings();
  const { isSaaS } = usePlatform();

  // Get authenticated and loading states from AuthContext.
  const { isAuthenticated, loading, isAdmin } = useAuthContext();
  const [offline, setOffline] = useState<boolean>(false);

  function offlineMode() {
    setOffline(true);
  }

  function onlineMode() {
    setOffline(false);
  }

  useEffect(() => {
    window.addEventListener("offline", offlineMode);
    window.addEventListener("online", onlineMode);

    return () => {
      window.removeEventListener("offline", offlineMode);
      window.removeEventListener("online", onlineMode);
    };
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      fetchUserSettings();
    }

    // eslint-disable-next-line
  }, [isAuthenticated]);

  if (offline) {
    return (
      <>
        <Header />
        <OfflinePage />
        <Footer />
      </>
    );
  }

  return (
    <>
      <Header />

      <main style={{ position: "relative" }}>
        {!loading ? (
          <Routes>
            {isAuthenticated ? (
              <>
                <Route index path="/" element={<Navigate to="/projects/" />} />

                <Route
                  path="/projects/"
                  element={
                    <Suspense fallback={<LoadingScreen />}>
                      <Projects />
                    </Suspense>
                  }
                />

                <Route
                  path="/contact/"
                  element={
                    <Suspense fallback={<LoadingScreen />}>
                      <ContactUs />
                    </Suspense>
                  }
                />

                <Route path="/create/">
                  <Route
                    path="task/:projectId"
                    element={
                      <Suspense fallback={<LoadingScreen />}>
                        <CreateTask />
                      </Suspense>
                    }
                  />
                  <Route
                    path="file/:projectId"
                    element={
                      <Suspense fallback={<LoadingScreen />}>
                        <UploadFile />
                      </Suspense>
                    }
                  />
                </Route>

                <Route path="/task">
                  <Route
                    path=":taskId"
                    element={
                      <Suspense fallback={<LoadingScreen />}>
                        <TaskDetails />
                      </Suspense>
                    }
                  />
                </Route>

                <Route path="/file">
                  <Route
                    path=":fileId"
                    element={
                      <Suspense fallback={<LoadingScreen />}>
                        <FilePage />
                      </Suspense>
                    }
                  />

                  <Route
                    path=":fileId/update/columns"
                    element={
                      <Suspense fallback={<LoadingScreen />}>
                        <FileUpdateColumnsPage />
                      </Suspense>
                    }
                  />
                </Route>

                <Route
                  path="/search"
                  element={
                    <Suspense
                      fallback={<LoadingScreen text={t("searching")} />}
                    >
                      <Search />
                    </Suspense>
                  }
                />

                <Route
                  path="/profile"
                  element={
                    <Suspense
                      fallback={<LoadingScreen text={t("loading-profile")} />}
                    >
                      <Profile />
                    </Suspense>
                  }
                />

                <Route
                  path="/account/delete/"
                  element={
                    <Suspense
                      fallback={
                        <LoadingScreen text={t("loading-delete-acount-page")} />
                      }
                    >
                      <DeleteAccountPage />
                    </Suspense>
                  }
                />

                {isSaaS && (
                  <Route
                    path="/credits"
                    element={
                      <Suspense
                        fallback={
                          <LoadingScreen text={t("loading-credits-page")} />
                        }
                      >
                        <Credits />
                      </Suspense>
                    }
                  />
                )}

                <Route
                  path="/inform-payment"
                  element={
                    <Suspense fallback={<LoadingScreen />}>
                      <InformPayment />
                    </Suspense>
                  }
                />

                <Route
                  path="/learn/"
                  element={
                    <Suspense fallback={<LoadingScreen />}>
                      <Learn />
                    </Suspense>
                  }
                />

                <Route
                  path="/files/unused/"
                  element={
                    <Suspense fallback={<LoadingScreen />}>
                      <UnusedFilesPage />
                    </Suspense>
                  }
                />

                <Route
                  path="/docs/"
                  element={
                    <Suspense fallback={<LoadingScreen />}>
                      <DocsPage />
                    </Suspense>
                  }
                />

                {isAdmin && isSaaS && (
                  <Route
                    path="/credit-management"
                    element={
                      <Suspense fallback={<LoadingScreen />}>
                        <CreditManagement />
                      </Suspense>
                    }
                  />
                )}
              </>
            ) : (
              <>
                <Route
                  index
                  path="/"
                  element={
                    <Suspense fallback={<LoadingScreen />}>
                      <Landing />
                    </Suspense>
                  }
                />

                <Route
                  path="/contact/"
                  element={
                    <Suspense fallback={<LoadingScreen />}>
                      <ContactUs />
                    </Suspense>
                  }
                />

                <Route path="/projects/" element={<Navigate to="/" />} />

                <Route
                  path="/learn/"
                  element={
                    <Suspense fallback={<LoadingScreen />}>
                      <LearnForLanding />
                    </Suspense>
                  }
                />

                <Route path="/create/">
                  <Route path="task/:projectId" element={<LoginRedirector />} />
                  <Route path="file/:projectId" element={<LoginRedirector />} />
                </Route>

                <Route path="/task">
                  <Route path=":taskId" element={<LoginRedirector />} />
                </Route>

                <Route path="/file">
                  <Route path=":fileId" element={<LoginRedirector />} />
                  <Route
                    path=":fileId/update/columns"
                    element={<LoginRedirector />}
                  />
                </Route>

                <Route path="/inform-payment" element={<LoginRedirector />} />
                <Route path="/credits" element={<LoginRedirector />} />
                <Route path="/profile" element={<LoginRedirector />} />
                <Route path="/search" element={<LoginRedirector />} />
                <Route
                  path="/credit-management"
                  element={<LoginRedirector />}
                />
                <Route path="/files/unused/" element={<LoginRedirector />} />
                <Route path="/account/delete/" element={<LoginRedirector />} />
                <Route path="/docs/" element={<LoginRedirector />} />
              </>
            )}
            <Route
              path="/terms/"
              element={
                <Suspense fallback={<LoadingScreen />}>
                  <TermsOfUsePage />
                </Suspense>
              }
            />
            <Route
              path="/privacy/"
              element={
                <Suspense fallback={<LoadingScreen />}>
                  <PrivacyPage />
                </Suspense>
              }
            />
            <Route
              path="*"
              element={
                <Suspense fallback={<LoadingScreen />}>
                  <Error404 />
                </Suspense>
              }
            />
          </Routes>
        ) : (
          <LoadingScreen text={t("ability-is-loading")} />
        )}
      </main>

      <ScrollToTop />

      <MessagesContainer />

      <Footer />
    </>
  );
}

export default App;
