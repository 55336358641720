import { regular, solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { useAuthContext } from "Context/AuthContextProvider";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { DropdownListDirection } from "Components/Common/Dropdown/DropdownList";
import { useFetchUnusedFiles } from "Components/Layout/Navbar/Navbar.hooks";
import { useTranslation } from "react-i18next";
import { useUserSettings } from "Context/UserSettingsProvider";
import { usePlatform } from "Context/PlatformContextProvider";
import { useEffect, useState } from "react";

import Dropdown from "Components/Common/Dropdown";
import DropdownList from "Components/Common/Dropdown/DropdownList";
import DropdownListItem from "Components/Common/Dropdown/DropdownListItem";
import DropdownDivider from "Components/Common/Dropdown/DropdownDivider";
import CreditsItemForSaas from "./Components/CreditsItemForSaas";

interface NavbarExtraDropdownAuthenticatedProps {
  handleToggleLanguage: () => void;
}

function NavbarExtraDropdownAuthenticated({
  handleToggleLanguage,
}: NavbarExtraDropdownAuthenticatedProps) {
  // Destructing `t` function for translation.
  const { t } = useTranslation();
  const { isSaaS } = usePlatform();
  const { username, logout } = useAuthContext();
  const { unusedFiles } = useFetchUnusedFiles();
  const { pathname } = useLocation();
  const { changingUserLocale, locale } = useUserSettings();

  // Set the search params a boolean states for detecting unused files are empty or not.
  const [searchParams, setSearchParams] = useSearchParams();
  const [unusedFilesHasInticator, setUnusedFilesHasInticator] =
    useState<boolean>(true);

  function handleLogout() {
    // Redirect to home when user is logged out.
    nav("/");
    logout();
  }

  const nav = useNavigate();

  useEffect(() => {
    if (unusedFiles?.length === 0) {
      setSearchParams({ "empty-unused-files": "true" });
    }
  }, [unusedFiles]);

  useEffect(
    () =>
      setUnusedFilesHasInticator(
        searchParams.get("empty-unused-files") === "true"
      ),

    [searchParams]
  );

  return (
    <Dropdown
      downIcon
      hasIndicator={
        unusedFiles && unusedFiles.length > 0 && !unusedFilesHasInticator
      }
      buttonProps={{
        title: t("show-user-settings"),
        children: (
          <>
            {username.length > 10
              ? username.substring(0, 10) + "..."
              : username}
          </>
        ),
        variant: "outlined",
        color: "primary",
        size: "normal",
        iconLeft: regular("user"),
      }}
    >
      <DropdownList openTo={DropdownListDirection.LEFT}>
        <DropdownListItem
          selected={pathname === "/profile"}
          onClick={() => nav("/profile")}
          icon={solid("user")}
        >
          <span>{t("my-account")}</span>
        </DropdownListItem>

        {unusedFiles && unusedFiles.length > 0 && !unusedFilesHasInticator && (
          <DropdownListItem
            selected={pathname === "/files/unused/"}
            onClick={() => nav("/files/unused/")}
            icon={solid("file")}
            hasInditacor
          >
            <span>{t("unused-files")}</span>
          </DropdownListItem>
        )}

        <DropdownListItem
          onClick={handleToggleLanguage}
          className="d-grid-mobile"
          disabled={changingUserLocale}
          icon={solid("globe")}
        >
          <span style={{ textTransform: "uppercase" }}>{locale}</span>
        </DropdownListItem>

        <DropdownListItem
          onClick={handleLogout}
          icon={solid("right-from-bracket")}
        >
          <span>{t("sign-out")}</span>
        </DropdownListItem>

        {isSaaS && (
          <>
            <DropdownDivider />
            <CreditsItemForSaas />
          </>
        )}
      </DropdownList>
    </Dropdown>
  );
}

export default NavbarExtraDropdownAuthenticated;
